import React from "react";
import ProjectRequestLine from "./projectRequestLine";

const ProjectRequestLines = ({openRequests, calendar, classSelection, xwidth, pstate }) => {
	return (
		<React.Fragment>
			{openRequests
				.filter(
					x =>
						classSelection.includes("ALL") ||
						classSelection.includes(x.requirement.resclass.id)
				).map(x => (
					<ProjectRequestLine calendar={calendar} xwidth={xwidth} pstate={pstate} data={x} key={x.key} />
				))}
		</React.Fragment>
	);
};

export default ProjectRequestLines;
