import React from "react";
import moment from "moment";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
	Table,
	Button,
	Icon,
	Message,
	Checkbox,
	Dropdown,
	Popup as SemanticPopup,
} from "semantic-ui-react";
import CajotExport from "../components/exports/cajotExport.js";
import DayViewHeader from "../components/dayview/header.js";
import DayViewJob from "../components/dayview/job.js";
import WahlImg from "../images/wahl.png";
import { SingleDatePicker } from "react-dates";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
import { latLngBounds, AwesomeMarkers } from "leaflet";
import Color from "color";
import SaveImg from "../images/save.png";

const truckCategories = [
	"90723e25-d1f4-465b-bc9e-a52ceb591fdf",
	"9f844b9f-9d03-430e-ab33-d75540e2c77d",
	"049df5df-87c7-48e0-bb85-aa10c9339d37",
	"a43521c0-b494-404f-b888-4517053775d6",
	"8e57da05-2cf3-44d6-b68c-61be08d2881d",
	"62a61c8a-7b8e-4abd-92b8-f549033c54c5",
	"56b88134-da00-4be0-8738-03dfdbbd3814",
	"38bca57f-4b4f-4b77-872a-1518296c55ec",
	"860cf680-9551-4e93-8aaf-07f29f3995bb",
	"3d46f1a8-5bf8-40eb-bbe4-f8548d1c804b"
];

const polierCategories = [
	"fbe20c5b-3582-4cf7-9a2e-5912fb50ada2",
	"7f58a484-1a61-4227-82a3-1154c01a6d7f",
	"429ce866-3624-473e-9f31-aad492011f12",
	"5c243a7e-cfc9-45ab-9437-a26282802ba8",
];

const positionIsValid = (statePos) => {
	return !(
		!statePos ||
		statePos.lat === 0 ||
		statePos.lng === 0 ||
		statePos.zoom === 0
	);
};

class DayViewPage extends React.Component {
	hasTrucks = false;

	constructor(props) {
		super(props);

		for (let truckCategory of truckCategories) {
			if (props.app.resources.classes.has(truckCategory)) {
				this.hasTrucks = true;
				break;
			}
		}

		this.state = {
			focused: false,
			widths: {},
			processFilter: [],
			sortColumn: null,
			sortDirection: "none",
			showNumberHumans: false,
			showNumberTrucks: false,
			showNumberMachines: false,
			showNameHumans: true,
			showNameTrucks: true,
			showNameMachines: true,
			sizeControl: false,
		};

		this.columnClick = this.columnClick.bind(this);
		this.setDrag = this.setDrag.bind(this);
	}

	setDrag(key, val) {
		this.setState((pS) => {
			return { widths: { ...pS.widths, [key]: val } };
		});
	}

	toggleSizeControl() {
		this.setState((ps) => {
			if (ps.sizeControl) {
				return { sizeControl: false, widths: {} };
			} else {
				return { sizeControl: true };
			}
		});
	}

	columnClick(column, e) {
		if (e.target.classList.contains("dragger")) return false;
		const multisort = ["project", "time", "material"];
		if (!this.hasTrucks) multisort.push("trucks");
		const changes = {
			multisort: {
				none: "ascending green",
				"ascending green": "descending green",
				"descending green": "ascending red",
				"ascending red": "descending red",
				"descending red": "none",
			},
			singlesort: {
				none: "ascending",
				ascending: "descending",
				descending: "none",
			},
		};
		this.setState((prevState) => {
			if (prevState.sortColumn === column) {
				const next =
					changes[multisort.includes(column) ? "multisort" : "singlesort"][
						prevState.sortDirection
					];
				return {
					sortColumn: next === "none" ? null : column,
					sortDirection: next,
				};
			} else {
				const next =
					changes[multisort.includes(column) ? "multisort" : "singlesort"][
						"none"
					];
				return {
					sortColumn: column,
					sortDirection: next,
				};
			}
		});
	}

	componentDidMount() {
		//check if it has trucks!
		try {
			this.props.appCtx.setEditButtons(
				<div className="topbutton" onClick={() => this.props.app.ui.save()}>
					<img alt="Save" src={SaveImg} />
				</div>,
				<div
					className="topbutton"
					onClick={() => this.props.app.ui.setDayView(false)}
				>
					<img alt="Back" src={WahlImg} />
				</div>
			);
		} catch (e) {}
	}

	componentDidUpdate(prevProps) {}

	componentWillUnmount() {
		this.props.appCtx.setEditButtons(false);
	}

	sort(a, b) {
		const spaceship = (p, q) => (p < q ? -1 : p > q ? 1 : 0);
		const getSum = (ox) => {
			let sum = 0;
			for (let order of ox.values()) {
				if (order.deleted) continue;
				sum += order.amount;
			}
			return sum;
		};
		const getFirstName = (
			collection,
			sub,
			filter = (x) => true,
			checkPolier = false
		) => {
			let firstName = "";
			for (let element of collection.values()) {
				if (
					element.deleted ||
					!element[sub] ||
					element[sub].deleted ||
					!filter(element[sub])
				)
					continue;
				if (
					firstName === "" ||
					firstName.localeCompare(element[sub].name) === 1
				)
					firstName = element[sub].name;
			}
			return firstName;
		};

		const up = this.state.sortDirection.includes("ascending") ? 1 : -1;
		const suffix = this.state.sortDirection.includes("red")
			? "_red"
			: this.state.sortDirection.includes("green")
			? "_green"
			: "";

		switch (this.state.sortColumn + suffix) {
			case "project_green":
				return up * a.project.name.localeCompare(b.project.name);
			case "project_red":
				return up * a.project.bstn.localeCompare(b.project.bstn);
			case "contact":
				return (
					up *
					getFirstName(a.project.$contacts, "person").localeCompare(
						getFirstName(b.project.$contacts, "person")
					)
				);
			case "lock":
			 	return up * spaceship(a.project.fixed ? 0 : 1, b.project.fixed ? 0 : 1);
			case "process":
				return up * a.process.name.localeCompare(b.process.name);
			case "time_green":
				return up * spaceship(a.start.valueOf(), b.start.valueOf());
			case "time_red":
				return (
					up *
					spaceship(
						a.end.valueOf() - a.start.valueOf(),
						b.end.valueOf() - b.start.valueOf()
					)
				);
			case "material_red":
				return up * spaceship(getSum(a.$orders), getSum(b.$orders));
			case "material_green":
				return (
					up *
					getFirstName(a.$orders, "material").localeCompare(
						getFirstName(b.$orders, "material")
					)
				);
			case "trucks_red":
				return up * spaceship(getSum(a.$disposition), getSum(b.$disposition));
			case "trucks_green":
				return (
					up *
					getFirstName(a.$disposition, "type").localeCompare(
						getFirstName(b.$disposition, "type")
					)
				);
			case "humans":
				return (
					up *
					getFirstName(
						a.$deployments,
						"resource",
						(x) => x.resclass.human,
						true
					).localeCompare(
						getFirstName(b.$deployments, "resource", (x) => x.resclass.human)
					)
				);
			case "machines":
				return (
					up *
					getFirstName(
						a.$deployments,
						"resource",
						(x) => !x.resclass.human && !truckCategories.includes(x.resclass.id)
					).localeCompare(
						getFirstName(
							b.$deployments,
							"resource",
							(x) =>
								!x.resclass.human && !truckCategories.includes(x.resclass.id)
						)
					)
				);
			case "trucks":
				return (
					up *
					getFirstName(a.$deployments, "resource", (x) =>
						truckCategories.includes(x.resclass.id)
					).localeCompare(
						getFirstName(b.$deployments, "resource", (x) =>
							truckCategories.includes(x.resclass.id)
						)
					)
				);
			default:
				return 0;
		}
	}

	render() {
		const jobs = this.props.app.projects
			.getJobsBetween({
				start: moment(this.props.app.ui.calStart)
					.startOf("day")
					.valueOf(),
				end: moment(this.props.app.ui.calStart)
					.endOf("day")
					.valueOf(),
			})
			.filter(
				(x) =>
					this.state.processFilter.length === 0 ||
					this.state.processFilter.includes(x.process.template.id)
			);

		const planRes = this.props.app.ui.rights.has("PLAN_RESOURCES");

		const mapSettings = {};
		const markers = [];
		let bounds = false;

		if (this.props.app.ui.showDayViewMap) {
			for (let job of jobs) {
				const pos = job.safePosition;

				if (!positionIsValid(pos)) continue;

				if (!bounds) bounds = latLngBounds([pos.lat, pos.lng]);
				bounds.extend([pos.lat, pos.lng]);
				//console.log(job.id);
				const color = Color(job.process.template.color);
				const csscolor = color.hex().replace(/#/, "");

				markers.push({
					latlng: [pos.lat, pos.lng],
					zoom: pos.zoom,
					key: job.id,
					name: job.project.name + ": " + job.process.name,
					icon: AwesomeMarkers.icon({
						icon: "picon-" + job.process.template.icon,
						background:
							"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 20 27' height='27mm' width='20mm'%3E%3Cg transform='translate(-87.825496,-152.17657)' id='layer1'%3E%3Cpath d='m 107.8255,162.07902 c -1e-5,5.46897 -5.18032,11.99135 -10.000003,17.09755 -4.348631,-5.07173 -9.999997,-11.62858 -10.000001,-17.09755 -2e-6,-5.46896 4.477152,-9.90245 10.000001,-9.90245 5.522853,0 10.000003,4.43349 10.000003,9.90245 z' style='opacity:1;fill:%23" +
							csscolor +
							";stroke:none;stroke-width:0.237034;stroke-miterlimit:2.875' id='path921' /%3E%3C/g%3E%3C/svg%3E%0A",
						iconColor: color.isLight() ? "black" : "white",
					}),
				});
			}

			if (!bounds) {
				mapSettings.center = [
					this.props.t("stdPos.lat", 50.8),
					this.props.t("stdPos.lng", 10.5),
				];
				mapSettings.zoom = this.props.t("stdPos.zoom", 6);
			} else if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
				mapSettings.center = markers[0].latlng;
				mapSettings.zoom = 14;
			} else {
				mapSettings.bounds = bounds.pad(0.3);
			}
		}

		//console.log(this.state);

		return (
			<div id="dayViewPage" className="dayViewPage">
				<div id="dayview_buttons" className="buttonLine">
					<div className="buttonBlock">
						<Button.Group>
							<Button
								icon="angle double left"
								onClick={() =>
									this.props.app.ui.setCalStart(
										moment(this.props.app.ui.calStart)
											.add(-1, "day")
											.valueOf()
									)
								}
							/>
							<SingleDatePicker
								style={{
									minWidth: 200,
								}}
								date={moment(this.props.app.ui.calStart).startOf("day")} // momentPropTypes.momentObj or null,
								id="sdp" // PropTypes.string.isRequired,
								numberOfMonths={3}
								onDateChange={(date) =>
									this.props.app.ui.setCalStart(date.valueOf())
								} // PropTypes.func.isRequired,
								focused={this.state.focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
								onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired,
								placeholder={this.props.t("choose")}
								hideKeyboardShortcutsPanel
								withPortal={true}
								displayFormat="ddd, DD. MMMM YYYY"
								isOutsideRange={() => false}
							/>
							<Button
								icon="angle double right"
								onClick={() =>
									this.props.app.ui.setCalStart(
										moment(this.props.app.ui.calStart)
											.add(1, "day")
											.valueOf()
									)
								}
							/>
						</Button.Group>
						<Dropdown
							multiple
							className="wideDrowdown"
							selection
							onChange={(e, data) => {
								console.log(data.value);
								this.setState({ processFilter: data.value });
							}}
							placeholder={this.props.t("allProcesses")}
							options={Array.from(
								this.props.app.basedata.processTemplates.values()
							)
								.filter((x) => !x.deleted)
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((x) => ({
									text: x.name,
									value: x.id,
									key: x.id,
									icon: "picon-" + x.icon,
									style: {
										backgroundColor: x.color,
										color: new Color(x.color).isLight() ? "black" : "white",
									},
								}))}
							value={this.state.processFilter}
						/>
					</div>
					<div className="buttonBlock">
						{this.props.app.ui.modules.has("EXPORT_CAJOT") ? (
							<CajotExport currentDate={this.props.app.ui.calStart} />
						) : null}
						<Button
							active={this.props.app.ui.showDayViewMap}
							onClick={() => this.props.app.ui.setDayViewMap(!this.props.app.ui.showDayViewMap)}
							icon="map"
						/>
						<Button
							active={this.state.sizeControl}
							onClick={() => this.toggleSizeControl()}
							icon="resize horizontal"
						/>
						<SemanticPopup
							pinned
							on="click"
							position="bottom right"
							trigger={<Button icon="setting" />}
						>
							<Table basic="very" celled collapsing>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell />
										<Table.HeaderCell>
											{this.props.t("sorting.number")}
										</Table.HeaderCell>
										<Table.HeaderCell>
											{this.props.t("sorting.name")}
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									<Table.Row>
										<Table.Cell textAlign="center">
											<Icon name="users" />
										</Table.Cell>
										<Table.Cell textAlign="center">
											<Checkbox
												checked={this.state.showNumberHumans}
												onChange={(e, data) =>
													this.setState({ showNumberHumans: data.checked })
												}
											/>
										</Table.Cell>
										<Table.Cell textAlign="center">
											<Checkbox
												checked={this.state.showNameHumans}
												onChange={(e, data) =>
													this.setState({ showNameHumans: data.checked })
												}
											/>
										</Table.Cell>
									</Table.Row>
									{this.hasTrucks ? (
										<Table.Row>
											<Table.Cell textAlign="center">
												<Icon name="truck" />
											</Table.Cell>
											<Table.Cell textAlign="center">
												<Checkbox
													checked={this.state.showNumberTrucks}
													onChange={(e, data) =>
														this.setState({ showNumberTrucks: data.checked })
													}
												/>
											</Table.Cell>
											<Table.Cell textAlign="center">
												<Checkbox
													checked={this.state.showNameTrucks}
													onChange={(e, data) =>
														this.setState({ showNameTrucks: data.checked })
													}
												/>
											</Table.Cell>
										</Table.Row>
									) : null}
									<Table.Row>
										<Table.Cell textAlign="center">
											<Icon name="picon-excavator" />
										</Table.Cell>
										<Table.Cell textAlign="center">
											<Checkbox
												checked={this.state.showNumberMachines}
												onChange={(e, data) =>
													this.setState({ showNumberMachines: data.checked })
												}
											/>
										</Table.Cell>
										<Table.Cell textAlign="center">
											<Checkbox
												checked={this.state.showNameMachines}
												onChange={(e, data) =>
													this.setState({ showNameMachines: data.checked })
												}
											/>
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						</SemanticPopup>
					</div>
				</div>

				{this.props.app.ui.showDayViewMap ? (
					<Map {...mapSettings} id="map_map">
						<TileLayer url="https://maps.bpo-asphalt.de/tile.php?z={z}&x={x}&y={y}&key=dboth&type=standard" />
						{markers.map((m) => (
							<Marker icon={m.icon} key={m.key} position={m.latlng}>
								<Popup>{m.name}</Popup>
							</Marker>
						))}
					</Map>
				) : null}
				<div class="tableWrapper">
					{jobs.length ? (
						<Table
							celled
							sortable
							className={this.state.sizeControl ? "sizeControlled" : null}
						>
							<DayViewHeader
								sortColumn={this.state.sortColumn}
								sortDirection={this.state.sortDirection}
								columnClick={this.columnClick}
								hasTrucks={this.hasTrucks}
								setDrag={this.setDrag}
								widths={this.state.widths}
							/>
							<Table.Body>
								{jobs
									.sort((a, b) => this.sort(a, b))
									.map((job) => {
										return (
											<DayViewJob
												truckCategories={truckCategories}
												key={job.id}
												jobId={job.id}
												widths={this.state.widths}
												planRes={planRes}
												polierCategories={polierCategories}
												startResourceEditor={
													this.props.app.ui.startResourceEditor
												}
												startJobEditModal={this.props.app.ui.startJobEditModal}
												showNumberHumans={this.state.showNumberHumans}
												showNameHumans={this.state.showNameHumans}
												showNumberTrucks={this.state.showNumberTrucks}
												showNameTrucks={this.state.showNameTrucks}
												showNumberMachines={this.state.showNumberMachines}
												showNameMachines={this.state.showNameMachines}
												handleResClick={this.props.calendarCtx.handleResClick.bind(
													this.props.calendarCtx
												)}
												hasTrucks={this.hasTrucks}
											/>
										);
									})}
							</Table.Body>
						</Table>
					) : (
						<Message
							icon="calendar times outline"
							header={this.props.t("nothingFoundMessage.header")}
							content={this.props.t("nothingFoundMessage.message")}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default inject("app")(
	withTranslation()(withRouter(observer(DayViewPage)))
);
