import React from "react";
import { observer, inject } from "mobx-react";
import { strToDate } from "../../../helpers/calendar";
import { Popup, Icon } from "semantic-ui-react";

class ProjectRequestLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	static getDate(props, x) {
		return (props.app.ui.calDuration * x) / props.xwidth;
	}

	static getWidth(props, x) {
		return (x * props.xwidth) / props.app.ui.calDuration;
	}

	render() {
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;

		const data = this.props.data;
		const boxes = [];

		const lineHeight = 30;
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;
		let projectId = "";
		for (let [personalId, request] of Object.entries(data.days)) {
			let start = ProjectRequestLine.getX(this.props, strToDate(personalId));
			let end = ProjectRequestLine.getX(this.props, strToDate(personalId, 1));
			let processes = [request.process.id];
			let isBeingMoved = this.props.pstate.dayMap.has(personalId);

			let pos = 0;
			let offset = 0;

			if (this.props.pstate.pid === "") {
			} else if (
				isBeingMoved &&
				(this.props.pstate.sub === "main" ||
					processes.includes(this.props.pstate.sub))
			) {
				offset = this.props.pstate.dayMap.get(personalId);
			}
			projectId = request.project.id;
			const marked =
				this.props.calendar.state.selected.includes(
					projectId + "#" + request.process.id + "#" + personalId
				) &&
				data.requirement.id === this.props.calendar.state.lastRequirement.id;

			const hovered =
				this.props.app.ui.mouseOver.day === personalId &&
				this.props.app.ui.mouseOver.resource === data.key;

			boxes.push(
				<div
					key={personalId}
					className={
						(offset !== 0 || pos !== 0 ? "request_box moved" : "request_box") +
						(hovered ? " hovered" : "") +
						(marked ? " marked" : "")
					}
					onClick={
						(this.props.app.ui.modules.has("REQUEST_PLANNING") &&
							this.props.app.ui.rights.has("PLAN_REQUESTS")) ||
						this.props.app.ui.modules.has("PLAN_RESOURCES")
							? (e) =>
									this.props.calendar.handleResClick(
										e,
										request.project.id,
										request.process.id,
										personalId,
										data.requirement
									)
							: null
					}
					onMouseEnter={(e) =>
						this.props.app.ui.setMouseOver({
							projects: [],
							processes: processes,
							resource: data.key,
							day: personalId,
						})
					}
					onMouseLeave={(e) => this.props.app.ui.resetMouseOver()}
					style={{
						cursor: "pointer",
						position: "absolute",
						left: start + 1 + offset,
						width: end - start - 1,
						height: boxHeight - 1,
						top: padding,
					}}
				>
					{request.comment.length ? (
						<Popup
						position='right center'
							content={request.comment}
							trigger={<Icon name="comment" className="requestPopupTrigger"
							 />}
						/>
					) : null}
				</div>
			);
		}

		return (
			<tr key={"tr" + data.key} className={"project_requirement"}>
				<td
					className="project_requirement_head sticky"
					style={{
						cursor: "pointer",
						left: 0,
						zIndex: 3,
						verticalAlign: "top",
					}}
				>
					<div
						className="resource_name"
						style={{
							whiteSpace: "nowrap",
							height: lineHeight,
						}}
					>
						<div
							className="imageInner"
							style={{
								backgroundImage: "url(" + data.requirement.imageUrl + ")",
							}}
						/>
						<div
							className="nameInner"
							onClick={
								(this.props.app.ui.modules.has("REQUEST_PLANNING") &&
									this.props.app.ui.rights.has("PLAN_REQUESTS")) ||
								this.props.app.ui.modules.has("PLAN_RESOURCES")
									? (e) =>
											this.props.calendar.handleResClick(
												e,
												projectId,
												false,
												false,
												data.requirement
											)
									: null
							}
						>
							{data.requirement.name}
						</div>
					</div>
				</td>
				<td>
					<div
						className="project_body"
						style={{
							zIndex: 2,
							width: width,
							height: lineHeight,
							position: "relative",
						}}
					>
						{boxes}
					</div>
				</td>
			</tr>
		);
	}
}

export default inject("app")(observer(ProjectRequestLine));
