import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CalPage from "./calpage";
import { observer, inject } from "mobx-react";
import ConfirmButton from "../components/calendar/controls/confirmButton";
//import DevTools from "mobx-react-devtools";
import Header from "../components/structure/header";
import Navigation from "../components/structure/navigation";
import ProjectEditPage from "./projectedit";
import GroupPage from "./groups";
import MapPage from "./map";
import QuestionPage from "./questions";

import MessagePopup from "../components/basedata/messagePopup";
import ReportPage from "./report";
import LoginWindow from "../components/login";

import {
	Dimmer,
	Loader,
	Icon,
	Button,
	Header as XHeader
} from "semantic-ui-react";
import LogoutImg from "../images/logout.png";
import SaveImg from "../images/save.png";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editButtons: false,
			saveButton: null,
			backButton: null
		};
	}

	getButtons() {
		if (this.state.editButtons) {
			return (
				<React.Fragment>
					{this.state.saveButton}
					{this.state.backButton}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{this.saveButton()}
					{this.logoutButton()}
				</React.Fragment>
			);
		}
	}

	logoutButton() {
		return (
			<ConfirmButton
				question="questions.logout"
				ok="yes"
				cancel="no"
				onClick={() => this.props.app.ui.logout()}
				trigger={
					<div className="topbutton">
						<img alt="Logout" src={LogoutImg} />
					</div>
				}
				checkSkip={() => !this.props.app.ui.saveNecessary()}
			/>
		);
	}

	saveButton() {
		return (
			<div className="topbutton" onClick={() => this.props.app.ui.save()}>
				<img alt="Save" src={SaveImg} />
			</div>
		);
	}

	setEditButtons(save, back = false) {
		if (save) {
			this.setState({
				editButtons: true,
				saveButton: save === true ? null: save,
				backButton: back
			});
		} else {
			this.setState({
				editButtons: false,
				saveFunction: null,
				backFunction: null
			});
		}
	}

	componentDidMount() {
		this.props.app.ui.setMaxMonth();
	}

	render() {
		return this.props.app.ui.loginNecessary ? (
			<LoginWindow error={this.props.app.ui.wasLoggedIn ? "lost" : ""} />
		) : (
			<React.Fragment>
				<Header
					buttons={this.getButtons()}
					fullscreen={this.props.app.ui.fullscreen}
				/>
				<Navigation />
				<main className={this.props.app.ui.fullscreen ? "fullscreen" : ""}>
					<MessagePopup />
					{this.props.app.ui.fullyLoaded ? (
						<Switch>
							<Route exact path="/" render={() => <CalPage appCtx={this} />} />
							{this.props.app.ui.modules.has("RESOURCE_MAP") && this.props.app.ui.rights.has("RESOURCE_MAP") ? (<Route
								exact
								path="/map"
								render={() => <MapPage appCtx={this} />}
							/>) : null}
							{this.props.app.ui.modules.has("DAYREPORTS") && this.props.app.ui.rights.has("SHOW_DAYREPORT") ? (<Route
								exact
								path="/reports"
								render={() => <QuestionPage appCtx={this} />}
							/>) : null}
							{this.props.app.ui.rights.has("EDIT_GROUPS") ? (<Route
								exact
								path="/groups/:id?"
								render={() => <GroupPage appCtx={this} />}
							/>):null}
							
							{this.props.app.ui.modules.has("RESOURCE_ANALYSIS") && this.props.app.ui.rights.has("RESOURCE_ANALYSIS") ? (<Route
								exact
								path="/report/:id?"
								render={() => <ReportPage appCtx={this} />}
							/>) : null}
							<Route
								exact
								path="/project/:id?"
								render={() => <ProjectEditPage appCtx={this} />}
							/>
						</Switch>
					) : null}

					{this.props.app.ui.networkError ? (
						<Dimmer active inverted>
							<XHeader className="connectionError" icon>
								<Icon name="wifi" />
								{this.props.t("loader.networkError")}
								<XHeader.Subheader>
									<Button
										onClick={x =>
											this.props.app.ui.networkError === "saving"
												? this.props.app.ui.save()
												: this.props.app.ui.loadData("basedata")
										}
									>
										{this.props.t("loader.tryAgain")}
									</Button>
									{this.props.app.ui.fullyLoaded ? (
										<Button onClick={x => this.props.app.ui.cancelSave()}>
											{this.props.t("cancel")}
										</Button>
									) : null}
								</XHeader.Subheader>
							</XHeader>
						</Dimmer>
					) : (this.props.app.ui.isLoading && !this.props.app.ui.backgroundLoading  ? (
						<Dimmer active inverted>
							<Loader size="large" inverted>
								{(!this.props.app.ui.loaderProcessing &&
								this.props.app.ui.loaderTries > 1
									? this.props.t("loader.tries", {
											tries: this.props.app.ui.loaderTries
									  }) + ": "
									: "") +
									this.props.t(
										"loader." +
											this.props.app.ui.currentLoader +
											(this.props.app.ui.loaderProcessing ? "_processing" : "")
									)}
							</Loader>
						</Dimmer>
					) : null)}
				</main>
			</React.Fragment>
		);
	}
}

export default inject("app")(withTranslation()(withRouter(observer(App))));
